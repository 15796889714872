const SYNCER_API = "https://982a8237.nip.io";
const BACKEND_API = "https://proportunity.pythonanywhere.com/api/";

const API_ENDPOINTS = {
  GET_PHONE_NUMBER: (id: number) => `${SYNCER_API}/phone_number/${id}`,
  GET_DEPOSIT: (phone_number: number) =>
    `${SYNCER_API}/deposits/${phone_number}`,
  GET_INSTALMENT: (phone_number: number) =>
    `${SYNCER_API}/instalments/${phone_number}`,
  GET_MONEYBOX: (phone_number: number) =>
    `${SYNCER_API}/moneyboxes/${phone_number}`,
  GET_MORTGAGE: (phone_number: number) =>
    `${SYNCER_API}/mortgages/${phone_number}`,
  // REGISTER: (id: number) => `${SYNCER_API}/phone_number/${id}`,
  CREATE_SUPPORT: () => `${BACKEND_API}create-support-ticket/`,
  CREATE_MONEYBOX: () => `${BACKEND_API}create-hadj-kopilka/`,
  CREATE_DEPOSIT: () => `${BACKEND_API}create-deposit/`,
  CREATE_MORTGAGE: () => `${BACKEND_API}create-ipoteka/`,
};

export default API_ENDPOINTS;
